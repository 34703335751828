import { Builder, BuilderComponent, builder } from '@builder.io/react'
import React from 'react'
import Seo from '../components/util/Seo'
import { TemplateMap } from '../templates/builder/TemplateMap'

const NotFoundPage: React.FC = ({ location }: any) => {
  console.log('NOT FOUND PAGE')
  // checking if the current action is to create or edit builder dynamic page
  if (Builder.isPreviewing || Builder.isEditing) {
    // console.log('NotFoundPage::Window Information', window.location)
    // console.log('NotFoundPage::Builder Information', Builder)
    // console.log('NotFoundPage:: Location', location)

    console.log('NotFoundPage:: Editing Model from Builder', builder.editingModel)
    const modelName = builder.editingModel || 'page'
    const modelNameArr = modelName.split('-')
    let constructingStr = ''
    for (let i = 0; i < modelNameArr.length; i++) {
      if (i === 0) {
        constructingStr += modelNameArr[i]
      } else {
        let str = modelNameArr[i]
        constructingStr += str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      }
    }
    console.log('NotFoundPage:: Constructed Builder model', constructingStr)

    const editingModel = constructingStr

    // const params = new URLSearchParams(window.location.search)
    // const paramModel = params.get('model')
    if (editingModel) {
      console.log('NotFoundPage:: Model', editingModel)
      const existingBuilderComponent = TemplateMap[editingModel]
      console.log('NotFoundPage:: Found template', existingBuilderComponent)

      return existingBuilderComponent ? existingBuilderComponent : <BuilderComponent model={editingModel} />
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <img
                className="h-12 w-auto"
                src="https://cdn.builder.io/api/v1/image/assets%2F15ee91a008d0421b81a1dba017629b14%2F6a66abf19a2744f6880b5dc6b2ffa5c1"
                alt="different dog logo"
              />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold uppercase tracking-wide text-indigo-600">404 error</p>
              <h1 className="mt-2 text-4xl tracking-tight text-gray-900 sm:text-5xl">Page not found.</h1>
              <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
              <div className="mt-6">
                <a href="/" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
        {/* <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Contact Support
            </a>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Status
            </a>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Twitter
            </a>
          </nav>
        </footer> */}
      </div>
    </>
  )
}

export default NotFoundPage
